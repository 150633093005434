import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { Enums } from '../models/enums';

@Component({
  selector: 'fm-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private router:Router,
              private auth:AuthService) { }

  ngOnInit() {
    if(!this.auth.isUserLoggedIn()) {
      this.router.navigate(['/login']);
    }
    if(this.auth.isUserLoggedIn() && this.auth.userProfile.usertype === Enums.USER_TYPE_OWNER) {
      this.router.navigate(['/studioapp/settings/studios']);
    } else if (this.auth.isUserLoggedIn() && this.auth.userProfile.usertype === Enums.USER_TYPE_TRAINEE) {
      this.router.navigate(['/traineeapp/membership']);
    }
  }

}
