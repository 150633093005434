import { UpdatePasswordComponent } from './update-password/update-password.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { LoginComponent } from './login/login.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { UserActivationComponent } from './user-activation/user-activation.component';

export const AuthRoutes = [
  { path: '', redirectTo: 'signup', pathMatch: 'full' },
  { path: 'register', component: SignUpComponent },
  { path: 'login', component: LoginComponent },
  { path: 'recover', component: ForgetPasswordComponent},
  { path: 'updatePassword', component: UpdatePasswordComponent},
  { path: 'user/account-activation', component: UserActivationComponent},
  { path: 'user/reset-pwd', component:UpdatePasswordComponent}
];
