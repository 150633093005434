import { Observable } from "rxjs";
import { EventEmitter } from "@angular/core";
import { FieldTypes } from "../../models/field-types";

export interface FieldI {
  name:string;
  type:string;
  label:string;
  sort?:boolean;
  filter?:boolean;
  validators:string[];
  choices?:any[];
  dynchoices?:Observable<any[]>;
  required?:boolean;
  reverse?:string;
  equalname?:string;
  validationpattern?:string;
  notifyonchange?:object;
  lookupValue?:string;
  displayFn?:any;
  searchStr?:any;
  focusListener?:EventEmitter<boolean>;
  min?:number;
  max?:number;
  requiredStr?:string;
  tooltip?:string;
  oneLine?:FieldI[];
  icon?:string;
  initValue?:any;
  clearButton?:boolean;
  subtype?:string;
  hidden?:boolean;
  cssClass?:string;
  flex?:string;
  minDate?:Date;
  maxDate?:Date;
  prefix?:string;
  addToOneLine(f:FieldI);
}

export class Field implements FieldI {
  name:string;
  type:string;
  label:string;
  sort?:boolean;
  filter?:boolean;
  validators:string[];
  choices?:any[];
  dynchoices?:Observable<any[]>;
  required?:boolean;
  reverse?:string;
  equalname?:string;
  validationpattern?:string;
  notifyonchange?:object;
  lookupValue?:string;
  displayFn?:any;
  searchStr?:any;
  focusListener?:EventEmitter<boolean>;
  min?:number;
  max?:number;
  requiredStr?:string;
  tooltip?:string;
  icon?:string;
  oneLine?:FieldI[];
  clearButton?:boolean;
  initValue?:any;
  subtype?:string;
  hidden?:boolean;
  flex?:string;
  cssClass?:string;
  minDate?:Date;
  maxDate?:Date;
  prefix?:string;

  constructor(fieldConfig:any) {
    this.name = fieldConfig.name;
    this.type = fieldConfig.type;
    this.label = fieldConfig.label;
    this.sort = fieldConfig.sort;
    this.filter = fieldConfig.filter;
    this.validators = [];
    this.oneLine = [];
    this.oneLine.push(this);
    if(fieldConfig.validators) {
      for(let i=0; i<fieldConfig.validators.length; i++) {
        this.validators.push(fieldConfig.validators[i]);
      }
    } else {
      console.log("Validators for field " + this.name + " are missing");
    }
    this.choices = fieldConfig.choices;
    this.required = fieldConfig.required;
    this.equalname = fieldConfig.equalname;
    this.reverse = fieldConfig.reverse;
    this.validationpattern = fieldConfig.validationpattern;
    this.notifyonchange = fieldConfig.notifyonchange;
    this.dynchoices = fieldConfig.dynchoices;
    this.lookupValue = fieldConfig.lookupValue;
    this.displayFn = fieldConfig.displayFn;
    this.searchStr = fieldConfig.searchStr;
    this.focusListener = fieldConfig.focusListener;
    this.min = fieldConfig.min;
    this.max = fieldConfig.max;
    this.tooltip = fieldConfig.tooltip;
    this.requiredStr = this.required?"*":"";
    this.icon = fieldConfig.icon;
    this.initValue = fieldConfig.initValue;
    this.clearButton = fieldConfig.clearButton?fieldConfig.clearButton:false;
    this.subtype = fieldConfig.subtype;
    this.hidden = fieldConfig.hidden;
    this.cssClass = fieldConfig.cssClass;
    this.flex = fieldConfig.flex;
    this.minDate = fieldConfig.minDate;
    this.maxDate = fieldConfig.maxDate;
    this.prefix = fieldConfig.prefix;

    if(!this.flex) {
      this.flex = "0 1 300px";
      if(this.type === FieldTypes.number ||
        this.type === FieldTypes.amount ||
        this.type === FieldTypes.date ||
        this.type === FieldTypes.time) {
          this.flex = "0 1 150px";
        }
    }

  }

  addToOneLine(f:FieldI) {
      this.oneLine.push(f);
      f.oneLine = [];
  }

  setRequired(required:boolean) {
    this.required = required;
    if(this.required) {
      this.validators.push('required');
    }
    this.requiredStr = this.required?"*":"";
  }
}

