import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder} from '@angular/forms';
import { AuthService} from '../../auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FieldConfigService } from '../../services/field-config.service';
import { Fields } from '../../models/fields';
import { Location } from '@angular/common';
import { User } from '../../core/models/user';

@Component({
  selector: 'fm-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.css']
})
export class UpdatePasswordComponent implements OnInit {

  resetPasswordForm: FormGroup;
  email:string;
  code:string;
  error = null;
  pwdReset = false;
  fieldConfigPanel = [];

  constructor(private fb: FormBuilder,
    private authService: AuthService,
    private router:Router,
    private fieldConfig: FieldConfigService,
    private location:Location,
    private route: ActivatedRoute) {

      this.fieldConfigPanel.push(
        this.fieldConfig.getFieldWithOverridableConfig(Fields.email, {required:true}));
      this.fieldConfigPanel.push(this.fieldConfig.getFieldWithOverridableConfig(Fields.password, {required:true}));
      this.fieldConfigPanel.push(this.fieldConfig.getFieldWithOverridableConfig(Fields.confirmpassword, {required:true}));

  }

  ngOnInit() {
    this.createForm();
    this.code = this.route.snapshot.queryParams.code;
  }

  private createForm() {
    this.resetPasswordForm = this.fb.group({sections:this.fb.array([])});
  }

  resetPwd() {
    if (this.resetPasswordForm.valid) {
      const user = new User(this.resetPasswordForm.value.sections[0]);
      this.error = null;
      this.authService.resetPwd(user, this.code).subscribe(
        data => {
          console.log("received result " + JSON.stringify(data));
          const result = data as any;
          if(result.valid) {
            this.pwdReset = true;
          } else {
            this.error = "Password reset failed.";
          }
        }
      );
    }

  }
  cancel() {
    this.location.back();
  }
  login() {
    this.router.navigate(["login"]);
  }
}
