import { Component, OnInit, Input} from '@angular/core';
import { FieldI } from '../../core/models/Field';
import { FormControl } from '@angular/forms';
import { FieldChangeHandler } from '../action/field-change-handler';

@Component({
  selector: 'fm-field',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.css']
})
export class FieldComponent implements OnInit {

  @Input() form: FormControl;
  @Input() fieldChangeHander: FieldChangeHandler;
  @Input() field: FieldI;

  public modules: {
      toolbar: false    // Snow includes toolbar by default
  };

  constructor() { }

  ngOnInit() {
  }

  fieldUpdated(value:any, field:string) {
    if(this.fieldChangeHander) {
      this.fieldChangeHander.fieldUpdated(value, field);
    }
  }

  private getValue(name:string) {

    return this.form.get(name).value;
  }

  private clearValue(name:string) {
    this.form.get(name).patchValue('');
    this.fieldUpdated(null, name);
  }

  public onChange(value:any, field:any) {
    if(field.notifyonchange != null) {
      field.notifyonchange.onChange(value, field);
    }
    this.fieldUpdated(value, field.name);
  }

}
