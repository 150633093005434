import { Component, OnInit, EventEmitter, Output} from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import { Enums } from '../../models/enums';


@Component({
  selector: 'fm-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  isLoggedIn$: Observable<boolean>;

  @Output() toggleSidenav = new EventEmitter<void>();
  // @Output() toggleNotificationSidenav = new EventEmitter<void>();

  constructor( private router: Router, private authService: AuthService ) { }

  ngOnInit() {
    this.isLoggedIn$ = this.authService.isLoggedIn;
  }
  get userProfile() {
    return this.authService.userProfile;
  }
  onLogout() {
    this.authService.logout();
  }
  profile() {
    if(this.authService.userProfile && this.authService.userProfile.usertype === Enums.USER_TYPE_TRAINEE) {
      this.router.navigate(["traineeapp/profile"]);
    }
  }
}
