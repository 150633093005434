import {FieldI} from '../../core/models/Field';

export class FieldConfigPanel {
  panelTitle:string;
  stripeCard:boolean; // for stripe integration
  panelFields:FieldI[];
  panelIcon:string;
  constructor(title?:string, stripeCard?:boolean) {
    this.panelTitle = title;
    this.panelFields = [];
    this.stripeCard = stripeCard?true:false;
  }
}

