// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  GOOGLE_API_KEY:'AIzaSyDgkTn4f8IKThizenYA815SRml77I4yo9U',
  // API_ENDPOINT: 'http://localhost:12345'
  // API_ENDPOINT: 'http://192.168.1.22:9000',
  // API_ENDPOINT: 'http://192.168.1.14:9000',
  //API_ENDPOINT: 'http://mygym4u.com:9000',
  API_ENDPOINT: 'http://fitmaze.com:9000',
  STRIPE_LINK:
    'https://connect.stripe.com/oauth/authorize?response_type=code&client_id=ca_DIBF1g5GecltCdQGL5ewUAUonBcvjNvF&scope=read_write'
};
