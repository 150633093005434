import { Component, OnInit, Input, ChangeDetectorRef, AfterContentInit, OnDestroy, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FmBaseDatastoreI } from '../../core/datastore/fm-base.datastore';
import { Location } from '@angular/common';
import { FieldChangeHandler } from '../action/field-change-handler';
import { FormDataHandler } from '../action/form-data-handler';
import { FieldConfigPanel } from '../models/field-config-panel';
import { Enrichment } from '../models/enrichment';
import { MatDialogRef } from '@angular/material';
import { SubscriptionLike as ISubscription } from 'rxjs';
import { StripeService, StripeCardComponent, ElementOptions, ElementsOptions } from "ngx-stripe";
import { SnackbarService } from '../../core/services/snackbar.service';

@Component({
  selector: 'fm-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit, OnDestroy, AfterContentInit, FieldChangeHandler {

  @ViewChild(StripeCardComponent) card: StripeCardComponent;

  @Input() formDataHandler:FormDataHandler<any>;
  @Input() datastore:FmBaseDatastoreI<any>;
  @Input() title:string;
  @Input() fieldConfigPanels:FieldConfigPanel;
  @Input() openInDialog:boolean;
  @Input() dialogRef: MatDialogRef<any>;
  @Input() id:string;
  @Input() multiTab:number; // 0 - no tabs, 1 - multi tabs, 2 - main + tabs
  @Input() editRecord:any;

  form: FormGroup;
  error = false;
  errorMessage = "";
  fieldChangeHander: FieldChangeHandler;
  private subscriptions:ISubscription[] = [];

  cardOptions: ElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        lineHeight: '40px',
        fontWeight: 300,
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    }
  };

  elementsOptions: ElementsOptions = {
    locale: 'en'
  };


  constructor(private fb: FormBuilder,
              private route: ActivatedRoute,
              private cdr: ChangeDetectorRef,
              private location:Location,
              private snackbar:SnackbarService)  {
                this.multiTab = 0;
  }

  ngOnInit() {
    this.createForm();
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    for(let i = 0; i < this.subscriptions.length; i++) {
      this.subscriptions[i].unsubscribe();
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  ngAfterContentInit() {
    this.fieldChangeHander = this;
    let recordId = null;
    if(this.openInDialog) {
      recordId = this.id;
    } else {
      recordId = this.route.snapshot.paramMap.get('id');
    }

    console.log("editing record " + recordId);
    // opening in editing mode
    if (recordId ) {
      this.title = this.formDataHandler.getEditTitle();
      this.formDataHandler.setEditMode(true);
      if(!this.editRecord) {
        this.editRecord = this.datastore.getRecord(recordId, "id");
      }
      this.formDataHandler.setEditId(this.editRecord.id);
      console.log("Editing record " + this.editRecord);
      if(this.editRecord != null) {
        const patchValue = this.formDataHandler.dataToFormValue(this.editRecord);
        this.form.patchValue(patchValue);
        console.log("Form Value " + JSON.stringify(this.form.value));
      }

      const disabledFields = this.formDataHandler.getNonModifiableFields();
      if(disabledFields ) {
        for(let i = 0; i < disabledFields.length; i++) {
          this.form.controls['sections'].get([disabledFields[i].section,
            disabledFields[i].field]).disable();

          }
      }
    } else {
      this.processEnrichment(this.formDataHandler.getInitialFormDefaults());
    }
  }

  private createForm() {
    this.form = this.fb.group({sections:this.fb.array([])});
  }

  submit() {

    const record = this.formDataHandler.formValueToData(this.form.value);
    if(this.card) {
      record['stripeCard'] = this.card.getCard();
    }
    let errorMsg = record.errorMessage;
    if(!errorMsg) {
      errorMsg = this.formDataHandler.validateSubmission(record);
    } else {
      errorMsg = record.errorMessage.text;
    }
    if(errorMsg) {
      this.handleError(errorMsg);
      return;
    }

    if(this.formDataHandler.isEditMode()) {
      this.datastore.modifyRecord(record);
    } else {
      this.datastore.addRecord(record);
    }
    const sub = this.datastore.opResult$().subscribe( result => {
      if(result === "") {
        this.handleSuccess();
      } else {
        this.handleError(result);
      }
    });
    this.subscriptions.push(sub);
  }

  handleSuccess() {
    if(this.openInDialog) {
      console.log("In DIALOG");
      this.dialogRef.close();
    } else {
      this.location.back();
    }

    this.snackbar.displaySuccessMessage("Successfully added record.");

  }

  handleError (message:string) {
    console.log(message);
    this.error = true;
    this.errorMessage = message;
  }

  cancel() {
    console.log("cancel button clicked...");
    if(this.openInDialog) {
      console.log("In DIALOG");
      this.dialogRef.close();
    } else {
      this.location.back();
    }

  }

  fieldUpdated(value:any, field:string) {
    const enrichment = this.formDataHandler.processFieldUpdate(value, field);
    this.processEnrichment(enrichment);
  }

  valueChange(value:any, field:string) {
    console.log("processing value change for field " + field);
    this.formDataHandler.processFieldValueChange(value, field);
  }

  private processEnrichment(enrichment:Enrichment) {

    console.log("Processing field update " + JSON.stringify(enrichment));
    console.log("Processing field update " + this.form.controls['sections']);
    if(enrichment.defaultValues) {

      this.form.controls['sections'].patchValue(enrichment.defaultValues);
      this.form.controls['sections'].updateValueAndValidity();
    }
    if(enrichment.disabledFields) {
      for(let i = 0; i < enrichment.disabledFields.length; i++) {
          this.form.controls['sections'].get([enrichment.disabledFields[i].section,
            enrichment.disabledFields[i].field]).disable();

      }
    }
    if(enrichment.enabledFields) {
      for(let i = 0; i < enrichment.enabledFields.length; i++) {
        this.form.controls['sections'].get([enrichment.enabledFields[i].section,
          enrichment.enabledFields[i].field]).enable();
      }
    }
  }
}
