export class Fields {
  static readonly fcname = "name";
  static readonly id = "id";
  static readonly email = "email";
  static readonly confirmemail = 'confirmemail';
  static readonly firstname = "firstname";
  static readonly lastname = "lastname";
  static readonly password = "password";
  static readonly confirmpassword = "confirmpassword";
  static readonly usertype = "usertype";
  static readonly homephone = "homephone";
  static readonly mobilephone = "mobilephone";
  static readonly foundFitMaze = "foundFitMaze";
  static readonly preferredContactMethod = "preferredContactMethod";

  static readonly address1 = "address1";
  static readonly address2 = "address2";
  static readonly city = "city";
  static readonly zipcode = "zipcode";
  static readonly state = "state";
  static readonly phonenumber = "phonenumber";
  static readonly faxnumber = "faxnumber";
  static readonly fitnessCategories = "fitnessCategories";
  static readonly amenities = "amenities";

  static readonly address = "address";

  static readonly className = "className";
  static readonly fitnessCategory = "fitnessCategory";
  static readonly fitnessSubCategory = "fitnessSubCategory";
  static readonly level = "level";
  static readonly duration = "duration";
  static readonly capacity = "capacity";
  static readonly strengthIntensity = "strengthIntensity";
  static readonly cardioIntensity = "cardioIntensity";
  static readonly flexibilityLevel = "flexibilityLevel";
  static readonly regenerationLevel = "regenerationLevel";
  static readonly description = "description";
  static readonly mustBring = "mustBring";
  static readonly studios = "studios";
  static readonly classCategory = "classCategory";

  static readonly studioId = "studioId";
  static readonly classId = "classId";
  static readonly eventType = "eventType";
  static readonly recurrenceWeekDays = "recurrenceWeekDays";
  static readonly classDate = "classDate";
  static readonly recurrenceStartDate = "recurrenceStartDate";
  static readonly recurrenceEndDate = "recurrenceEndDate";
  static readonly recurrenceStartTime = "recurrenceStartTime";
  static readonly recurrenceEndTime = "recurrenceEndTime";
  static readonly instructorId = "instructorId";
  static readonly traineeId = "traineeId";

  static readonly startTime = "startTime";
  static readonly endTime = "endTime";
  static readonly classEventStatus = "status";
  static readonly numRegistered = "numRegistered";
  static readonly waitList = "waitList";
  static readonly parentId = "parentId";

  static readonly paymentMethod = "paymentMethod";

  static readonly heartCondition="heartCondition";
  static readonly bloodPressure="bloodPressure";
  static readonly surgeryHistory="surgeryHistory";
  static readonly notes="notes";
  static readonly birthdate = "birthdate";
  static readonly weightLoss= "weightLoss";
  static readonly strength= "strength";
  static readonly cardio= "cardio";
  static readonly marathonTraining= "marathonTraining";

  static readonly yearsOfExperience = "yearsOfExperience";
  static readonly collegeOrProgram = "collegeOrProgram";
  static readonly accolades = "accolades";
  static readonly bioText = "bioText";
  static readonly gender = "gender";

  static readonly yoga = "yoga";
  static readonly cycling= "cycling";
  static readonly pilates= "pilates";
  static readonly martialarts= "martialarts";
  static readonly running= "running";
  static readonly zumba= "zumba";
  static readonly rockclimbing = "rockclimbing";
  static readonly barre= "barre";
  static readonly stretching = "stretching";
  static readonly prenatal="prenatal";
  static readonly crossfit="crossfit";

  static readonly planName = "planName";
  static readonly planType = "planType";
  static readonly limitted = "limitted";
  static readonly maxclass = "maxclass";
  static readonly expires = "expires";
  static readonly expdate = "expdate";
  static readonly planPrice = "planPrice";
  static readonly planStatus = "planStatus";
  static readonly planDiscountType = "planDiscountType";
  static readonly promotionCode = "promotionCode";
  static readonly traineeSearch = "traineeSearch";
  static readonly fullName = "fullName";

  static readonly classPaymentPlanId = "classPaymentPlanId";
  static readonly autoRenewal = "autoRenewal";
  static readonly planStartDate = "planStartDate";
  static readonly planEndDate = "planEndDate";
  static readonly usedclasses = "usedclasses";
  static readonly ppjStatus = "ppjStatus";
  static readonly pricePaid = "pricePaid";

  static readonly attendanceDate = "attendanceDate";
  static readonly attendanceTime = "attendanceTime";
  static readonly attendanceStatus = "attendanceStatus";

  static readonly registrationReqd = "registrationReqd";
  static readonly registrationBeginHours = "registrationBeginHours";
  static readonly waitListMax = "waitListMax";

  static readonly numAttended = "numAttended";

  static readonly traineeList = "traineeList";

  static readonly ownerid = "ownerid";

  static readonly stripeCode = "stripeCode";

  static readonly profilePicture = "profilePicture";
  static readonly studiosCheckbox = "studiosCheckbox";
  static readonly stripeUserId = "stripeUserId";
  static readonly stripeCustomerId = "stripeCustomerId";
  static readonly nameOnCard = "nameOnCard";
  static readonly saveCard = "saveCard";

  static readonly prorationAllowed = "prorationAllowed";
  static readonly acceptedStudios = "acceptedStudios";
  static readonly activeDuration = "activeDuration";


  static readonly baseRate = "baseRate";
  static readonly classDuration = "classDuration";
  static readonly classParticipants = "classParticipants";
  static readonly rate = "rate";
  static readonly rateType = "rateType";
  static readonly startDate = "startDate";
  static readonly endDate = "endDate";
  static readonly amount = "amount";
  static readonly classDateTime = "classDateTime";
  static readonly paymentDate = "paymentDate";
  static readonly periodStartDate = "periodStartDate";
  static readonly periodEndDate = "periodEndDate";
  static readonly totalClasses = "totalClasses";
  static readonly totalHours = "totalHours";
  static readonly instructorPaymentMethod = "instructorPaymentMethod";
  static readonly checkNumber = "checkNumber";
  static readonly planPurchaseDate = "planPurchaseDate";

  static readonly attendHistorySearchBy = "attendHistorySearchBy";

  static readonly searchStartDate = "searchStartDate";
  static readonly searchEndDate = "searchEndDate";

  static readonly instructorName = "instructorName";
  static readonly traineeName = "traineeName";
  static readonly lastCheckinTime = "lastCheckinTime";
}
