export class FieldTypes {
  static readonly email = "email";
  static readonly text = "text";
  static readonly password = "password";
  static readonly dropdown = "dropdown";
  static readonly amount = "amount";
  static readonly phone = "phone";
  static readonly checkbox = "checkbox";
  static readonly address = "address";
  static readonly textarea = "textarea";
  static readonly dynamicdropdown = "dynamicdropdown";
  static readonly dynamicmultiselect = "dynamicmultiselect";
  static readonly time = "time";
  static readonly date = "date";
  static readonly singlecheckbox = "singlecheckbox";
  static readonly autocomplete = "autocomplete";
  static readonly number = "number";
  static readonly tel = "tel";
  static readonly color = "color";
  static readonly texteditor = "texteditor";
  static readonly userlist = "userlist";
  static readonly editor = "editor";
  static readonly multiselect = "multiselect";
  static readonly image = "image";
  static readonly fullname = "fullname";
  static readonly radio = "radio";
}

