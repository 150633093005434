import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpErrorResponse} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Enums } from '../models/enums';
import { User } from '../core/models/user';
import * as moment from 'moment';
import { FmBaseService } from '../core/services/fm-base.service';

@Injectable( {
  providedIn: 'root',
})
export class AuthService extends FmBaseService<User> {
  private loggedIn = new BehaviorSubject<boolean>(false);
  private user:User = null;
  returnUrl:string;

  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  public isUserLoggedIn() {
    return this.loggedIn.value;
  }

  public get userProfile() {
    return this.user;
  }

  constructor(
    private router: Router,
    http: HttpClient,
    private route:ActivatedRoute
  ) {
    super(http, "AuthService");
    console.log("Auth constructor ");
    if(this.isSessionActive()) {
      this.user = JSON.parse(localStorage.getItem('user')) || null;
      this.loadView();
    }
  }

  login(username:string, password:string) {
    console.log("Loging user " + username);
    if (username !== '' && password !== '' ) {
      this.postCall("/user/login",  {password:password, email:username})
      .subscribe(user => {
        this.user = new User(user);
        this.setSession(this.user, user);
        this.loadView();
      });

    } else {
      this.router.navigate(['/login']);
    }
  }

  activateUser(code:string) {
    console.log("activating code " + code);
    return this.postCall("/auth/activate-user", {code:code});
  }

  forgotPwd(email:string) {
    return this.postCall("/auth/forgot-pwd", {email:email});
  }

  resetPwd(user:User, code:string) {
    return this.postCall("/auth/reset-pwd", {user:user, code:code});
  }

  private loadView() {
    if(this.user) {
      console.log("USER " + JSON.stringify(this.user));
      console.log("Return url " + this.route.snapshot.url);
      console.log("Router url " + this.router.url);
      // this doesn't work
     /* if(this.router.url && this.router.url.length > 0 && this.router.url === "/login") {

        if (this.user.usertype === Enums.USER_TYPE_OWNER) {
        this.router.navigate(['/studioapp/settings/studios']);
      } else if (this.user.usertype === Enums.USER_TYPE_TRAINEE) {
        this.router.navigate(['/traineeapp/membership']);
      }*/
    }
      this.loggedIn.next(true);

  }

  private setSession(user:User, authResult) {
    const expiresAt = moment().add(authResult.expiresIn,'minute');

    localStorage.setItem('idToken', authResult.token);
    localStorage.setItem("expiresAt", JSON.stringify(expiresAt.valueOf()) );
    localStorage.setItem("user", JSON.stringify(user));
  }

  private isSessionActive() {
    console.log("Checking if the session is active");
    return moment().isBefore(this.getExpiration());
  }

  getExpiration() {
    const expiration = localStorage.getItem("expiresAt");
    const expiresAt = JSON.parse(expiration);
    console.log("Session expires at " + expiresAt);
    return moment(expiresAt);
  }

  logout() {
    this.loggedIn.next(false);
    this.user = null;
    localStorage.removeItem("idToken");
    localStorage.removeItem("expiresAt");
    this.router.navigate(['/login']);
  }

  cancel() {
    this.router.navigate(["fitmaze"]);
  }
}


