import { HomeComponent } from "./home/home.component";
import { PageNotFoundComponent } from "./page-not-found.component";
import { Enums } from "./models/enums";
import { RoleGuard } from "./auth/role.guard";
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';


export const AvailableRoutes: any = [
  { path: "home", component: HomeComponent },
  { path: "fitmaze", component: HomeComponent },
  { path: "", component: HomeComponent },
  {
    path: 'auth',
    loadChildren: './auth/auth.module#AuthModule',
    data: { preload: false, delay: false },
  },
  {
    path: 'studioapp',
    canLoad: [RoleGuard],
    loadChildren: './studioapp/studioapp.module#StudioappModule',
    data: { preload: false, delay: false, expectedRole:Enums.USER_TYPE_OWNER },

  },
  {
    path: 'traineeapp',
    canLoad: [RoleGuard],
    loadChildren: './traineeapp/traineeapp.module#TraineeappModule',
    data: { preload: false, delay: false, expectedRole:Enums.USER_TYPE_TRAINEE },
  },
  /*{
    path: 'calendar',
    loadChildren: './calendar/fm-calendar.module#FmCalendarModule',
    data: { preload: false, delay: false },
  },*/

  // { path: '**', component: PageNotFoundComponent }

];

@NgModule({
  imports: [
    RouterModule.forRoot(
      AvailableRoutes,
      {
        enableTracing: false, // <-- debugging purposes only
      }
    )
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
