export class Enums {
  static readonly USER_TYPE_TRAINEE = "T";
  static readonly USER_TYPE_INTSTRUCTOR = "I";
  static readonly USER_TYPE_OWNER = "O";
  static readonly USER_TYPE_FC_EMPL = "E";

  static readonly CLASS_EVENT_STATUS_WAIT_OPEN = "waitOpen";
  static readonly CLASS_EVENT_STATUS_OPEN = "open";
  static readonly CLASS_EVENT_STATUS_WAIT_LIST = "waitList";
  static readonly CLASS_EVENT_STATUS_CLOSED = "closed";
  static readonly CLASS_EVENT_STATUS_CANCELED = "canceled";
  static readonly CLASS_EVENT_STATUS_DONE = "done";

  static readonly PLAN_DISCOUNT_TYPE_REGULAR = "R";
  static readonly PLAN_STATUS_ACTIVE= "A";
  static readonly CLASS_PACKAGE = "CP";
  static readonly SINGLE_CLASS = "SC";

  static readonly CALENDAR_EVENT_TYPE_RECURRING = "R";
  static readonly CALENDAR_EVENT_TYPE_INSTNACE = "I";
  static readonly CALENDAR_EVENT_TYPE_ONCE = "O";

  static readonly WEEKLY_PLAN = "WP";
  static readonly MONTHLY_PLAN = "MP";
  static readonly DURATION_PLAN = "DP";
  static readonly CLASS_PACK_PLAN= "CP";

  static readonly STATUS_ACTIVE = "A";
  static readonly STATUS_DISABLED = "D";

  static readonly INST_RATE_TYPE_HOURLY = "H";
  static readonly INST_RATE_TYPE_CLASS = "C";
  static readonly CLASS_CATEGORY_GROUP = "G";
  static readonly CLASS_CATEGORY_PRIVATE = "P";


  public static attendHistorySearchBy = [
    {label:"Member", value:"M"},
    {label:"Instructor", value:"I"},
    {label:"Class", value:"C"},
  ];

  public static instructorRateTypes = [
    {label:"Hourly", value:Enums.INST_RATE_TYPE_HOURLY},
    {label:"Per Class", value:Enums.INST_RATE_TYPE_CLASS},
  ];

    public static ageGroups = [
        {label:"12-17", value:1},
        {label:"18-25", value:2},
        {label:"26-35", value:3},
        {label:"36-45", value:4},
        {label:"46-55", value:5},
        {label:"56-65", value:6},
        {label:"65 and older", value:7},
        {label:"prefer not to disclose", value:8},
        ];

    public static genders = [
        {label:"male", value:"M"},
        {label:"female", value:"F"},
        {label:"prefer not to disclose", value:"N"},
    ];

    public static daily = [
        {label:"Mon", value:'Mon', name:"days_Mon"},
        {label:"Tue", value:'Tue', name:"days_Tue"},
        {label:"Wed", value:'Wed', name:"days_Wed"},
        {label:"Thur", value:'Thur', name:"days_Thur"},
        {label:"Fri", value:'Fri', name:"days_Fri"},
        {label:"Sat", value:'Sat', name:"days_Sat"},
        {label:"Sun", value:'Sun', name:"days_Sun"},
    ];

    public static planType = [
        {label:"Class Package", value:Enums.CLASS_PACK_PLAN},
        {label:"Weekly Package", value:Enums.WEEKLY_PLAN},
        {label:"Monthly Package", value:Enums.MONTHLY_PLAN},
        {label:"Duration", value:Enums.DURATION_PLAN}
    ];

    public static rateDiscountType = [
        {label:"Regular", value:'R'},
        {label:"Child", value:'C'},
        {label:"Student", value:'S'},
        {label:"Senior Citizen", value:'SC'},
    ];

    public static expirationType = [
        {label:"Never", value:'never'},
        {label:"Certain Date", value:'certain_date'},
    ];

    public static rateStatus = [
        {label:"Active", value:'A'},
        {label:"Disabled", value:'D'},
    ];

    public static foundFitMaze = [
        {label:'Web Ad', value:'W'},
        {label:'Friend', value:'F'},
        {label:'Flyer', value:'L'},
        {label:'Other', value:'O'}];

    public static strengthIntensity = [
            {label:'Strength Endurance - Repetitions (>12) - Intensity (% 1-RM, < 67%)', value:'SE'},
            {label:'Hypertrophy - Repetitions (6 - 12) - Intensity (% 1-RM, 67% - 85%)', value:'H'},
            {label:'Maximum Strength - Repetitions (< 6) - Intensity (% 1-RM, > 85%)', value:'SE'},
    ];
    public static exerciseLocation = [
        {label:'At Home', value:'H'},
        {label:'At Fitness Center', value:'F'},
    ];

    public static flexIntensity = [
        {label:'Slow', value:'S'},
        {label:'Moderate', value:'M'},
        {label: 'Fast and Challenging', value: 'FC'},
    ];

    public static intensity = [
      {label:"One", value:1},
      {label:"Two", value:2},
      {label:"Three", value:3},
      {label:"Four", value:4},
      {label:"Five", value:5},
      {label:"Six", value:6},
      {label:"Seven", value:7},
      {label:"Eight", value:8},
      {label:"Nine", value:9},
      {label:"Ten", value:10},

    ];
    public static classesLevel = [
        {label:'Beginner', value:'B', name:"beginner"},
        {label:'Intermediate', value:'I', name:"intermediate"},
        {label:'Advanced', value:'A', name:"advanced"},
        {label:'All', value:'All', name:"all"},
    ];

    public static groupOrPrivate = [
        {label:'Private Sessions', value:'P'},
        {label:'Group Classes', value:'G'},
    ];

    public static amenities = [
        {label:"Towel Service", value:1, name:"towelservice"},
        {label:"Locker Rooms", value:2, name:"lockerrooms"},
        {label:"Shower Area", value:3, name:"showerarea"},
        {label:"Snack Area/Juice Bar", value:4, name:"snackarea"},
        {label:"Lounge Area", value:5, name:"loungearea"},
        {label:"Steam Room", value:6, name:"steamroom"},
        {label:"Parking Area", value:7, name:"parkingarea"},
        {label:"Handicapped Accessbility", value:8, name:"hcaccess"},
        {label:"Child Care", value:9, name:"childcare"},
        {label:"Spa", value:10, name:"amtyspa"},
    ];

    public static fitnessGoals = [
        {label:"Lower body fat percentage", value:1, name:"lowbodyfat"},
        {label:"Get stroner", value:2, name:"getstronger"},
        {label:"Master a skill", value:3, name:"masterskill"},
        {label:"Lifestyle change", value:4, name:"lifechange"},
        {label:"Train for an event", value:5, name:"trainforevent"},
    ];

    public static exerciseIntensity = [
        {label:"Low, about 40-54% MHR", value:1},
        {label:"Moderate is 55-69% MHR", value:2},
        {label:"High is equal or greater than 70% MHR", value:3},
    ];

    public static activeExpired = [
      { label: "Active", value:'A'},
      { label: "Expired", value: 'E'}
    ];

    public static yesnonotsure = [
        {label:"Yes", value:1},
        {label:"No", value:2},
        {label:"Undecided", value:3},
    ];

    public static paymentMethod = [
        {label:"Credit Card", value:'CC'},
        {label:"Venmo/Other", value:'V'},
        {label:"Cash/Check", value:"C"},
        // {label:"ACH", value:"A"}
    ];
    public static paymentMethodOnline= [
      {label:"Credit Card", value:'CC'},
  ];

  public static paymentMethodDirect= [
    {label:"Venmo/Other", value:'V'},
    {label:"Cash/Check", value:"C"},
];
  public static instructorPaymentMethod = [
    {label:"Venmo/Other", value:'V'},
    {label:"Cash/Check", value:"C"},
  ];

    public static usertype = [
      {label:"Fitness Enthusiast", value:Enums.USER_TYPE_TRAINEE},
      {label:"Fitness Center Owner", value:Enums.USER_TYPE_OWNER},
      {label:"Fitness Instructor", value:Enums.USER_TYPE_INTSTRUCTOR},
      {label:"Fitness Center Employee", value:Enums.USER_TYPE_FC_EMPL},
    ];

    public static classCategory = [
      {label:"Group", value:"G"},
      {label:"Private Category 1", value:"P1"},
      {label:"Private Category 2", value:"P2"},
      {label:"Private Category 3", value:"P3"},
      {label:"Semi Private Category 1", value:"SP1"},
      {label:"Semi Private Category 2", value:"SP2"},
      {label:"Semi Private Category 3", value:"SP3"},
      {label:"Equipment Only", value:"E"}
    ];

      public static yogaSubCat = [
          {label:"Anusara", value:1, name:"anusara"},
          {label:"Ashtanga", value:2, name:"ashtanga"},
          {label:"Bikram", value:3, name:"bikram"},
          {label:"Vinyasa", value:4, name:"vinyasa"},
          {label:"Hatha", value:5, name:"hatha"},
          {label:"Hot Yoga", value:6, name:"hotyoga"},
          {label:"Restorative", value:7, name:"restorative"}
       ];

       public static cycleSubCat = [
        {label:"Steady-State Rides", value:1, name:"ssrides"},
        {label:"Power Intervals", value:2, name:"powerint"},

        {label:"Recovery Rides", value:3, name:"recrides"},
        {label:"Foundation Rides", value:4, name:"foundrides"},
        {label:"Long Rides", value:5, name:"shclimbs"},
        {label:"Long Hill Climbs", value:6, name:"lhclimbs"},
        {label:"Threshold Rides", value:7, name:"thresrides"},
        {label:"Lactate Intervals", value:8, name:"lacintervals"},
        {lable:"Speed Intervals", value:9, name:"speedintervals"}

       ];

       public static pilatesSubCat = [
         {label:"Mat", value:1, name:"mat"},
         {label:"Clinical", value:2, name:"clinical"},
         {label:"Contemporary Studio", value:3, name:"contstudio"},
         {label:"Group Reformer", value:4, name:"grpreformer"},
         {label:"Classical", value:5, name:"classical"}

       ];

       public static martialArtsSubCat = [
        {label:"Capoeira", value:1, name:"capoeira"},
        {label:"Karate", value:2, name:"karate"},
        {label:"Kickboxing", value:3, name:"kickboxing"},
        {label:"Krav Maga", value:4, name:"kravmaga"},
        {label:"Kung Fu", value:5, name:"kungfu"},
        {label:"Tae Kwon Do", value:6, name:"taekwondo"},
        {label:"Jujutsu", value:7, name:"jujutsu"},
        {label:"Sumo", value:8, name:"sumo"},
        {label:"Wrestling", value:9, name:"wrestling"},
        {label:"Aikido", value:10, name:"aikido"},
        {label:"Judo", value:11, name:"judo"},
        {label:"Tai Chi", value:12, name:"taichi"},
        {label:"MMA", value:13, name:"MMA"},
        {label:"Boxing", value:14, name:"boxing"}
       ];

       public static runningSubCat = [
        {label:"Recovery Run", value:1, name:"recoveryrun"},
        {label:"Base Run", value:2, name:"baserun"},
        {label:"Long Run", value:3, name:"longrun"},
        {label:"Progression Run", value:4, name:"progrun"},
        {label:"Fartlek", value:5, name:"fartlek"},
        {label:"Hill Repeats", value:6, name:"hillrepeats"},
        {label:"Tempo Run", value:7, name:"temporun"},
        {label:"Intervals", value:8, name:"intervals"}
       ];

        public static strengthTrainingSubCat = [
          {label:"Total Body Circuit Training", value:1, name:"totalbodyct"},
          {label:"Push-Pull Training", value:2, name:"pushpull"},
          {label:"Power Lifting Training", value:3, name:"powerlift"},
          {label:"Explosive Dynamic Training", value:4, name:"explosivedyn"},
          {label:"Muscular Isolation Training", value:5, name:"musculariso"},
          {label:"Cardio", value:6, name:"cardio"},
          {label:"Low Intensity, Long Duration", value:7, name:"lowintlowdur"},
          {label:"Medium Intensity, Medium Duration", value:8, name:"medintmeddur"},
          {label:"High Intensity, Short Duration", value:9, name:"highintshortdur"},
          {label:"Aerobic Interval", value:10, name:"aerobicint"},
          {label:"Anaerobic Interval", value:11, name:"anaerobicint"},
          {label:"Fartlek Training", value:12, name:"fartlek"},
          {label:"Circuit Training", value:13, name:"circuit"}
        ];

      public static zumbaSubCat = [
        {label:"Zumba Gold", value:1, name:"zumbagold"},
        {label:"Zumbatomic", value:2, name:"zumbaatomic"},
        {label:"Aqua Zumba", value:3, name:"aquazumba"},
        {label:"Zumba Toning", value:4, name:"zumbatoning"},
        {label:"Zumba In The Circuit", value:5, name:"zumbacircuit"}
      ];

      public static rockClimbingSubCat = [
        {label:"Traditional climbing", value:1, name:"traditional"},
        {label:"Sport climbing", value:2, name:"sport"},
        {label:"Top-roping", value:3, name:"toproping"},
        {label:"Bouldering", value:4, name:"bouldering"},
        {label:"Soloing", value:5, name:"soloing"},
        {label:"Speed climbing", value:6, name:"speed"}
      ];

      public static barreSubCat = [
        {label:"Cardio Barre", value:1, name:"cardio"},
        {label:"Pure Barre", value:2, name:"pure"},
        {label:"Bar Method", value:3, name:"bar"},
        {label:"Barre3", value:4, name:"barre3"},
        {label:"Lotte Berk Method", value:5, name:"lottebark"}
      ];

      public static stretchingSubCat = [
        {label:"Ballistic stretching", value:1, name:"ballistic"},
        {label:"Dynamic stretching", value:2, name:"dynamic"},
        {label:"Active stretching", value:3, name:"active"},
        {label:"Passive (or relaxed) stretching", value:4, name:"passive"},
        {label:"Static stretching", value:5, name:"static"},
        {label:"Isometric stretching", value:6, name:"isometric"},
        {label:"PNF stretching", value:7, name:"PNF"}
      ];


        public static fitnessCategories = [
            {
                label: "Yoga",
                value: 'Y',
                name:"yoga",
                subcategories: Enums.yogaSubCat
            },
            {
                label: "Cycling",
                value: 'C',
                name:"cycling",
                subcategories: Enums.cycleSubCat
            },
            {
                label: "Pilates",
                value: 'P',
                name:"pilates",
                subcategories: Enums.pilatesSubCat
            },

            {
              label: "Martial Arts",
              value: 'M',
              name:"martialarts",
              subcategories: Enums.martialArtsSubCat
            },
            {
              label: "Running",
              value: 'R',
              name:"running",
              subcategories: Enums.runningSubCat
            },
            {
              label: "Strength Training",
              value: 'T',
              name:"strength",
              subcategories: Enums.strengthTrainingSubCat
            },
            {
              label: "Zumba",
              value: 'Z',
              name:"zumba",
              subcategories: Enums.zumbaSubCat
            },
            {
              label: "Rock Climbing",
              value: 'K',
              name:"rockclimbing",
              subcategories: Enums.rockClimbingSubCat
            },
            {
              label: "Barre",
              value: 'B',
              name:"barre",
              subcategories: Enums.barreSubCat
            },
            {
              label: "Stretching",
              value: 'S',
              name:"stretching",
              subcategories: Enums.stretchingSubCat
            },
            {
              label: "Prenatal",
              value: 'N',
              name:"prenatal",
              subcategories: []
            },
            {
              label: "CrossFit",
              value: 'F',
              name:"crossfit",
              subcategories: []
            },

        ];

    public static states = [
        {
            label: "Alabama",
            value: "AL"
        },
        {
            label: "Alaska",
            value: "AK"
        },
        {
            label: "American Samoa",
            value: "AS"
        },
        {
            label: "Arizona",
            value: "AZ"
        },
        {
            label: "Arkansas",
            value: "AR"
        },
        {
            label: "California",
            value: "CA"
        },
        {
            label: "Colorado",
            value: "CO"
        },
        {
            label: "Connecticut",
            value: "CT"
        },
        {
            label: "Delaware",
            value: "DE"
        },
        {
            label: "District Of Columbia",
            value: "DC"
        },
        {
            label: "Federated labeles Of Micronesia",
            value: "FM"
        },
        {
            label: "Florida",
            value: "FL"
        },
        {
            label: "Georgia",
            value: "GA"
        },
        {
            label: "Guam",
            value: "GU"
        },
        {
            label: "Hawaii",
            value: "HI"
        },
        {
            label: "Idaho",
            value: "ID"
        },
        {
            label: "Illinois",
            value: "IL"
        },
        {
            label: "Indiana",
            value: "IN"
        },
        {
            label: "Iowa",
            value: "IA"
        },
        {
            label: "Kansas",
            value: "KS"
        },
        {
            label: "Kentucky",
            value: "KY"
        },
        {
            label: "Louisiana",
            value: "LA"
        },
        {
            label: "Maine",
            value: "ME"
        },
        {
            label: "Marshall Islands",
            value: "MH"
        },
        {
            label: "Maryland",
            value: "MD"
        },
        {
            label: "Massachusetts",
            value: "MA"
        },
        {
            label: "Michigan",
            value: "MI"
        },
        {
            label: "Minnesota",
            value: "MN"
        },
        {
            label: "Mississippi",
            value: "MS"
        },
        {
            label: "Missouri",
            value: "MO"
        },
        {
            label: "Montana",
            value: "MT"
        },
        {
            label: "Nebraska",
            value: "NE"
        },
        {
            label: "Nevada",
            value: "NV"
        },
        {
            label: "New Hampshire",
            value: "NH"
        },
        {
            label: "New Jersey",
            value: "NJ"
        },
        {
            label: "New Mexico",
            value: "NM"
        },
        {
            label: "New York",
            value: "NY"
        },
        {
            label: "North Carolina",
            value: "NC"
        },
        {
            label: "North Dakota",
            value: "ND"
        },
        {
            label: "Northern Mariana Islands",
            value: "MP"
        },
        {
            label: "Ohio",
            value: "OH"
        },
        {
            label: "Oklahoma",
            value: "OK"
        },
        {
            label: "Oregon",
            value: "OR"
        },
        {
            label: "Palau",
            value: "PW"
        },
        {
            label: "Pennsylvania",
            value: "PA"
        },
        {
            label: "Puerto Rico",
            value: "PR"
        },
        {
            label: "Rhode Island",
            value: "RI"
        },
        {
            label: "South Carolina",
            value: "SC"
        },
        {
            label: "South Dakota",
            value: "SD"
        },
        {
            label: "Tennessee",
            value: "TN"
        },
        {
            label: "Texas",
            value: "TX"
        },
        {
            label: "Utah",
            value: "UT"
        },
        {
            label: "Vermont",
            value: "VT"
        },
        {
            label: "Virgin Islands",
            value: "VI"
        },
        {
            label: "Virginia",
            value: "VA"
        },
        {
            label: "Washington",
            value: "WA"
        },
        {
            label: "West Virginia",
            value: "WV"
        },
        {
            label: "Wisconsin",
            value: "WI"
        },
        {
            label: "Wyoming",
            value: "WY"
        }
    ];


}
