import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { FakeBackendInterceptor } from './fake-backend';
import { AuthInterceptor } from '../auth/auth.interceptor';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  //{ provide: HTTP_INTERCEPTORS, useClass: FakeBackendInterceptor, multi: true },
];
