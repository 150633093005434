import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder} from '@angular/forms';
import { AuthService} from '../../auth/auth.service';
import { ActivatedRoute } from '@angular/router';
import { FieldConfigService } from '../../services/field-config.service';
import { Fields } from '../../models/fields';
import { Location } from '@angular/common';

@Component({
  selector: 'fm-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {

  forgetPasswordForm: FormGroup;
  email:string;

  fieldConfigPanel = [

  ];

  emailValidated = false;
  error = null;

  constructor(private fb: FormBuilder,
    private authService: AuthService,
    private fieldConfig: FieldConfigService,
    private location:Location,
    private route: ActivatedRoute) {

      this.fieldConfigPanel.push(
        this.fieldConfig.getFieldWithOverridableConfig(Fields.email, {required:true}));

  }

  ngOnInit() {
    this.createForm();
    this.email = this.route.snapshot.paramMap.get('email');
    if(this.email) {
      this.forgetPasswordForm.controls['sections'].patchValue({email:this.email});
      this.forgetPasswordForm.controls['sections'].updateValueAndValidity();
    }
  }

  private createForm() {
    this.forgetPasswordForm = this.fb.group({sections:this.fb.array([])});
  }

  sendEmail() {
    console.log('clicked sendEmail ' + JSON.stringify(this.forgetPasswordForm.value));
    this.error = null;
    if (this.forgetPasswordForm.valid) {
      this.authService.forgotPwd(this.forgetPasswordForm.value.sections[0].email).subscribe(
        data => {
          console.log("received result " + JSON.stringify(data));
          const result = data as any;
          if(result.valid) {
            this.emailValidated = true;
          } else {
            this.error = "User with this email is not registered.";
          }
        }
      );
    }

  }
  cancel() {
    this.location.back();
  }
}
