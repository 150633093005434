import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, PreloadAllModules } from '@angular/router';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AppMaterialModule } from './app-material/app-material.module';
import { FlexLayoutModule } from "@angular/flex-layout";
import { AgmCoreModule } from '@agm/core';
import { CalendarModule } from 'angular-calendar';
import { NgxStripeModule } from 'ngx-stripe';

import { AvailableRoutes } from './app.routing';
import { httpInterceptorProviders } from './helpers/index';

import { AppComponent } from './app.component';
import {
  MenuComponent,
  AppLayoutComponent,
  SidebarComponent,
  AccordionAnchorDirective,
  AccordionLinkDirective,
  AccordionDirective} from './applayout';
import { AuthGuard } from './auth/auth.guard';
import { HeaderComponent } from './applayout/header/header.component';
import { AuthModule } from './auth/auth.module';
import { StudioappModule } from './studioapp/studioapp.module';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '../../node_modules/@angular/material';
import { ConfirmDialogComponent } from './form/confirm-dialog/confirm-dialog.component';
import { TraineeappModule } from './traineeapp/traineeapp.module';
import { HomeComponent } from './home/home.component';
import { PageNotFoundComponent } from './page-not-found.component';
import { RoleGuard } from './auth/role.guard';
import { StudioRoutingModule } from './studioapp/studioapp.routes';
import {AppRoutingModule} from './app.routing';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true,
  minScrollbarLength: 20
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MenuComponent,
    AppLayoutComponent,
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    SidebarComponent,
    HomeComponent,
    PageNotFoundComponent
  ],
  entryComponents: [  ConfirmDialogComponent],
  imports: [
    BrowserModule,
    AppMaterialModule,
    HttpClientModule,
    // RouterModule,
    // RouterModule.forRoot(AvailableRoutes),
    BrowserAnimationsModule,
    AuthModule,
    AppRoutingModule,
    // StudioappModule,
    // StudioRoutingModule,
    // TraineeappModule,
    PerfectScrollbarModule,
    FlexLayoutModule ,
    CalendarModule.forRoot(),
    NgxStripeModule.forRoot('pk_test_NC7PHsqJfuam9QUqbcnaLq4k'),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDgkTn4f8IKThizenYA815SRml77I4yo9U'
    })
  ],
  providers: [ AuthGuard, RoleGuard,
    httpInterceptorProviders,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {height: '750px', width: '500px'}
    }
    // {provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher}
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }

