import { Component, OnInit, AfterContentInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, finalize } from "rxjs/operators";
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'fm-user-activation',
  templateUrl: './user-activation.component.html',
  styleUrls: ['./user-activation.component.css']
})
export class UserActivationComponent implements OnInit, AfterContentInit, OnDestroy {

  // http://localhost:4200/user/account-activation

  code: string;
  notValidated = true;
  error = null;

  private waitingValidation = new BehaviorSubject<boolean>(false);
  public waitingValidation$ = this.waitingValidation.asObservable();

  constructor(
    private auth: AuthService,
    private router:Router,
    private route: ActivatedRoute) {
  }

  ngOnInit() {

  }

  ngOnDestroy(): void {
   this.waitingValidation.complete();

  }

  login() {
      this.router.navigate(["login"]);
  }

  register() {
    this.router.navigate(["register"]);
  }

  ngAfterContentInit() {
    if(this.route.snapshot.queryParams.code ) {
      this.code = this.route.snapshot.queryParams.code;

      this.waitingValidation.next(true);
      this.auth.activateUser(this.code).subscribe(
        data => {
          this.notValidated = false;
          const retCode = data as any;
          if(retCode.valid) {
            console.log("Code is valid");
          } else {
            this.error = "Failed to activate the account. Invalid or expired code.";
            console.log("Error");
          }
        }
      );
      }
    }



  }

