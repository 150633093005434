import {Enrichment} from '../models/Enrichment';
import {FieldConfigPanel} from '../models/field-config-panel';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Inject } from '@angular/core';

export abstract class FormDataHandler<T> {
  editMode:boolean;
  editId:string;
  fieldConfigPanels:FieldConfigPanel[];
  title: string;
  formDataHandler: FormDataHandler<T>;
  openInDialog:boolean;
  id:string;
  multiTab:number;
  refData:any;
  editRecord:T;

  constructor(
    title:string,
    multitab:number,
    public dialogRef?: MatDialogRef<T>,
    @Inject(MAT_DIALOG_DATA) public data?: any) {
    this.title = title;
    this.multiTab = multitab;
    this.formDataHandler = this;
    this.openInDialog = this.dialogRef ? true : false;
    this.id = this.openInDialog ? data.id : null;
    this.refData = this.openInDialog?data.refData: null;
    this.editRecord = this.openInDialog?data.editRecord:null;
  }

  abstract dataToFormValue(record:any):any;
  abstract formValueToData(formvalue:any):any;
  abstract getEditTitle():string;

  setEditId(editId:string) {
    this.editId = editId;
  }
  getEditId() {
    return this.editId;
  }
  setEditMode(mode:boolean) {
    this.editMode = mode;
  }
  isEditMode() {
    return this.editMode;
  }

  processFieldUpdate(value:any, field:string):Enrichment {
    return {};
  }

  processFieldValueChange(value:any, field:string) {

  }

  getInitialFormDefaults():Enrichment {
    return {};
  }

  validateSubmission(record:any):string {
    return null; // empty error message
  }

  getNonModifiableFields():{section:number, field:string}[] {
    return null;
  }
}

