import { Fields } from '../models/fields';
import { FieldTypes } from '../models/field-types';
import { User } from '../core/models/user';
import { Enums } from '../models/enums';
import { DynamicFormComponent } from '../form/dynamic-form/dynamic-form.component';
import { Observable } from "rxjs";
import { EventEmitter, Injectable } from "@angular/core";
import { Field } from '../core/models/field';

export interface OverridableFieldConfigI {
  required?:boolean;
  sort?: boolean;
  filter?:boolean;
  validators?:string[];
  notifyonchange?:object;
  dynchoices?:Observable<any[]>;
  choices?:any[];
  lookupValue?:string;
  type?:string;
  displayFn?:any;
  searchStr?:any;
  focusListener?:EventEmitter<boolean>;
  clearButton?:boolean;
  subtype?:string;
  hidden?:boolean;
}

@Injectable({
  providedIn: 'root'
})
export class FieldConfigService {
  private fieldsCollection = new Map<string, any>();
  private fieldsConfig = [
    {name:Fields.fcname, type:FieldTypes.text, label:"Studio Name", sort:true, filter:true, validators:[]},
    {name:Fields.address1, type:FieldTypes.text, label:"Address Line 1",
      validationpattern:DynamicFormComponent.addressregex,validators:['pattern']},
    {name:Fields.address2, type:FieldTypes.text, label:"Address Line 2", validators:[]},
    {name:Fields.city, type:FieldTypes.text, label:"City",
      validationpattern:DynamicFormComponent.cityregex, validators:['pattern']},
    {name:Fields.state, type:FieldTypes.dropdown, label:"State", choices: Enums.states, validators:[]},
    {name:Fields.zipcode, type:FieldTypes.text, label:"ZipCode",
      validationpattern:DynamicFormComponent.zipcoderegex, validators:['pattern'], flex:"0 1 100px"},
    {name:Fields.phonenumber, type:FieldTypes.phone, label:"Phone Number", validators:[ 'phone']},
    {name:Fields.faxnumber, type:FieldTypes.text, label:"Fax Number", validators:[ 'phone']},
    {name:Fields.mobilephone, type:FieldTypes.phone, label:"Mobile Phone", validators:[ 'phone']},
    {name:Fields.homephone, type:FieldTypes.text, label:"Home Phone", validators:[ 'phone']},
    {name:Fields.email, type:FieldTypes.email, sort:true, label:"Email", equalname:Fields.confirmemail,
          reverse:"true", validators:['email']},
    {name:Fields.confirmemail, type:FieldTypes.email,
            label:"Confirm Email", equalname:Fields.email, validators:['email']},
    {name:Fields.fitnessCategories, type:FieldTypes.checkbox,
      choices:Enums.fitnessCategories, label:"Fitness Categories" , validators:[]},
    {name:Fields.amenities, type: FieldTypes.checkbox, choices: Enums.amenities, label: "Amenities", validators:[] },
    {name:Fields.usertype, type:FieldTypes.dropdown, label:"User Type", choices: Enums.usertype, validators:[]},
    {name:Fields.confirmpassword,type:FieldTypes.password, label:"Confirm Password",
      equalname:Fields.password, validators:['password']},
    {name:Fields.firstname, sort:true, type:FieldTypes.text, label:"First Name", validators:[]},
    {name:Fields.lastname, sort:true, type:FieldTypes.text, label:"Last Name", validators:[]},
    {name:Fields.password, type:FieldTypes.password, label:"Password", equalname:Fields.confirmpassword,
      reverse:"true", validators:['password']},
    {name:Fields.address, type:FieldTypes.address, label:"Address", validators:[]},

    {name: Fields.className, type:FieldTypes.text, label: "Class Name",
          tooltip:"Use short and catchy names for your classes, examples: Endless Cycle, Survival Bootcamp...", validators:[] },
    {name: Fields.fitnessCategory, type:FieldTypes.dropdown, choices:Enums.fitnessCategories,
        label:"Fitness Category", lookupValue:'fitnessCategory', validators:[]},
    {name: Fields.fitnessSubCategory, type:FieldTypes.dynamicdropdown, tooltip:"Select Fitness Category first..", label:"Sub Category", validators:[]},
    {name: Fields.level, type:FieldTypes.dropdown, choices:Enums.classesLevel, label: "Level",
            tooltip:"Class skill and difficulty level", lookupValue:'level',validators:[] },
    {name: Fields.duration, type:FieldTypes.number, label: "Class Duration", tooltip:"Class duration in minutes", validators:[], min:0, max:1000},
    {name: Fields.capacity, type:FieldTypes.number, label: "Class Capacity", tooltip:"Maximum number of class participants",
        validators:[], min:0, max:1000},
    {name: Fields.cardioIntensity, type:FieldTypes.number,  label:"Cardio Intensity" , tooltip:"Enter number 1 to 10",
            validators:[], min:0, max:10},
    {name: Fields.strengthIntensity, type:FieldTypes.number, label: "Strength Intensity",
        tooltip:"Enter number 1 to 10", validators:[], min:0, max:10},
    {name: Fields.flexibilityLevel, type:FieldTypes.number,  label: "Flexibility Level",
            tooltip:"Enter number 1 to 10",validators:[], min:0, max:10},
    {name: Fields.regenerationLevel, type:FieldTypes.number,  label: "Regeneration Level",
      tooltip:"Enter number 1 to 10", validators:[], min:0, max:10},
    {name: Fields.description, type:FieldTypes.editor, label: "Class Description", validators:[]},
    {name: Fields.mustBring, type:FieldTypes.textarea, label: "Must Bring" , tooltip:"Items participants should bring to class", validators:[]},
    {name:Fields.studios, type:FieldTypes.dynamicmultiselect, label:"Studio", lookupValue:'FitnessCenter',
             validators:[]},
    {name:Fields.classCategory, type:FieldTypes.dropdown, choices:Enums.classCategory,
          label:"Class Category", validators:[]},
    {name:Fields.classId, type:FieldTypes.dynamicdropdown,
         label:"Class Name", lookupValue:'ClassTemplate', validators:[]},
    {name:Fields.studioId, type:FieldTypes.dynamicdropdown, lookupValue:'FitnessCenter',
            label:"Studio", validators:[]},
    { name:Fields.eventType, type:FieldTypes.singlecheckbox, label:"Recurring?", validators:[]},
    { name:Fields.recurrenceWeekDays, type:FieldTypes.checkbox, choices:Enums.daily, label: "Select Days", validators:[]},
    { name:Fields.classDate, type:FieldTypes.date, label: "Class Date", validators:[]},
    { name:Fields.recurrenceStartDate, type:FieldTypes.date, label: "Start Date", validators:[]},
    { name:Fields.recurrenceEndDate, type:FieldTypes.date, label: "End Date", validators:[]},
    { name:Fields.recurrenceStartTime, type: FieldTypes.time, label: "Start Time", validators:[] },
    { name:Fields.recurrenceEndTime, type:FieldTypes.time, label: "End Time" , validators:[]},
    { name:Fields.startTime, type: FieldTypes.time, label: "Start Time", validators:[] },
    { name:Fields.endTime, type:FieldTypes.time, label: "End Time" , validators:[]},
    { name:Fields.classDateTime, type: FieldTypes.text, label: "Class Date and Time", validators:[] },
    { name:Fields.instructorId, type:FieldTypes.dynamicdropdown,
      lookupValue:'Instructor', label: "Instructor Name", validators:[] },

      //      static readonly paymentType = "paymentType";
    {name:Fields.paymentMethod, type:FieldTypes.dropdown, choices:Enums.paymentMethod,
        label:"Payment Method", validators:[]},
    { name:Fields.birthdate, type:FieldTypes.date, label: "Birth Date", validators:[]},
    { name:Fields.heartCondition, type:FieldTypes.singlecheckbox, label: "Heart Condition", validators:[]},
    { name:Fields.bloodPressure, type:FieldTypes.singlecheckbox, label: "Blood Pressure", validators:[]},
    { name:Fields.surgeryHistory, type:FieldTypes.singlecheckbox, label: "Surgery History", validators:[]},
    { name:Fields.weightLoss, type:FieldTypes.singlecheckbox, label: "Weight Loss", validators:[]},
    { name:Fields.strength, type:FieldTypes.singlecheckbox, label: "Strength", validators:[]},
    { name:Fields.cardio, type:FieldTypes.singlecheckbox, label: "Cardio", validators:[]},
    { name:Fields.marathonTraining, type:FieldTypes.singlecheckbox, label: "Marathon Training", validators:[]},
    { name:Fields.notes, type:FieldTypes.textarea, label: "Notes", validators:[]},
    { name:Fields.yearsOfExperience, type:FieldTypes.text, label: "Years of Experience", validators:[]},
    { name:Fields.collegeOrProgram, type:FieldTypes.text, label: "College or Program", validators:[]},
    { name:Fields.accolades, type:FieldTypes.textarea, label: "Accolades", validators:[]},
    { name:Fields.bioText, type:FieldTypes.textarea, label: "Biography", validators:[]},
    { name: Fields.gender, type:FieldTypes.dropdown, choices:Enums.genders, label: "Biological Gender", validators:[]},

    { name:Fields.yoga, type:FieldTypes.singlecheckbox, label: "Yoga", validators:[]},
    { name:Fields.cycling, type:FieldTypes.singlecheckbox, label: "Cycling", validators:[]},
    { name:Fields.pilates, type:FieldTypes.singlecheckbox, label: "Pilates", validators:[]},
    { name:Fields.martialarts, type:FieldTypes.singlecheckbox, label: "Martial Arts", validators:[]},
    { name:Fields.running, type:FieldTypes.singlecheckbox, label: "Running", validators:[]},
    { name:Fields.zumba, type:FieldTypes.singlecheckbox, label: "Zumba", validators:[]},
    { name:Fields.rockclimbing, type:FieldTypes.singlecheckbox, label: "Rock Climbing", validators:[]},
    { name:Fields.barre, type:FieldTypes.singlecheckbox, label: "Barre", validators:[]},
    { name:Fields.stretching, type:FieldTypes.singlecheckbox, label: "Stretching", validators:[]},
    { name:Fields.prenatal, type:FieldTypes.singlecheckbox, label: "Prenatal", validators:[]},
    { name:Fields.crossfit, type:FieldTypes.singlecheckbox, label: "Cross Fit", validators:[]},

    { name:Fields.planName, type:FieldTypes.text, label: "Plan Name", validators:[], flex:"0 1 380px"},
    { name:Fields.planType, type:FieldTypes.dropdown, choices:Enums.planType, lookupValue:"planType", label: "Plan Type", validators:[]},
    { name:Fields.limitted, type:FieldTypes.singlecheckbox, label: "Limited?", validators:[]},
    { name:Fields.maxclass, type:FieldTypes.number, label: "Maximum Classes Included", min:1, max:1000, validators:[], flex:"0 1 180px"},
    { name:Fields.expires, type:FieldTypes.singlecheckbox, label: "Expires?",
          tooltip:"Indicate if the plan has limitted duration", validators:[]},
    { name:Fields.expdate, type:FieldTypes.date, label: "Plan Offer Exp Date",
          tooltip:"Set for temporary offers (student summer plan, etc.)", validators:[], flex:"0 1 180px"},
    { name:Fields.planPrice, type:FieldTypes.amount, label: "Price", validators:[]},
    { name:Fields.planStatus, type:FieldTypes.dropdown, choices:Enums.rateStatus, label: "Status", validators:[]},
    { name:Fields.planDiscountType, type:FieldTypes.dropdown, choices:Enums.rateDiscountType,
      lookupValue:Fields.planDiscountType, label: "Discount", validators:[]},
    { name:Fields.classCategory, type:FieldTypes.dropdown, choices:Enums.classCategory, label: "Category",
          tooltip:"Group, private, or semi-private", validators:[]},
    { name:Fields.promotionCode, type:FieldTypes.text, label: "Promotion Code", validators:[]},
    { name:Fields.classPaymentPlanId, type:FieldTypes.dynamicdropdown,  label:"Payment Plan", validators:[]},
    { name:Fields.autoRenewal, type:FieldTypes.singlecheckbox, label: "Auto Renewal", validators:[]},
    { name:Fields.planStartDate, type:FieldTypes.date, label: "Start Date", validators:[]},
    { name:Fields.planEndDate, type:FieldTypes.date, label: "End Date", validators:[]},
    { name:Fields.traineeSearch, type:FieldTypes.autocomplete, label: "Trainee Search", validators:[],
        displayFn:this.displayUserFn, searchStr:this.userSearchString},
    { name:Fields.attendanceDate, type:FieldTypes.date, label: "Attend Date", validators:[]},
    { name:Fields.attendanceTime, type:FieldTypes.text, label: "Attend Class Time", validators:[]},
    { name:Fields.attendanceStatus, type:FieldTypes.time, label: "Attendance Status", validators:[]},
    { name:Fields.usedclasses, type:FieldTypes.number, label: "Classes Used", validators:[]},
    { name:Fields.ppjStatus, type:FieldTypes.text, label: "Status", validators:[]},
    { name:Fields.ppjStatus, type:FieldTypes.text, label: "Status", validators:[]},
    { name:Fields.pricePaid, type:FieldTypes.amount, label: "Amount Owed", validators:[]},
    { name:Fields.registrationReqd, type:FieldTypes.singlecheckbox, label: "Registration Required?",
        tooltip:"Check if advance registration is recommended", validators:[]},
    { name:Fields.registrationBeginHours, type:FieldTypes.number, label: "Registration Begins",
            tooltip:"Enter number of hours prior to class start time (1 to 48)", validators:[], min:1, max:48},
    { name:Fields.waitListMax, type:FieldTypes.number, label: "Waiting List", tooltip:"Maximum size of the waiting list", validators:[], min:0, max:20},
    { name:Fields.numAttended, type:FieldTypes.text, label: "Num Checked-in", validators:[]},
    { name:Fields.numRegistered, type:FieldTypes.text, label: "Num Registered", validators:[]},
    { name:Fields.traineeList, type:FieldTypes.userlist, label: "Trainees", initValue:[]},
    { name:Fields.stripeCode, type:FieldTypes.text, label:"Stripe Code", validators:[]},
    { name:Fields.fullName, type:FieldTypes.text, sort:true, subtype:FieldTypes.fullname, label:"Last name, First name", validators:[]},
    { name:Fields.profilePicture, type:FieldTypes.image, label:"Profile Picture", validators:[]},
    { name:Fields.studiosCheckbox, type: FieldTypes.checkbox, label: "Select Studio(s)", validators:[] },
    { name:Fields.stripeCustomerId, type:FieldTypes.text, label:"Stripe Customer ID", validators:[]},
    { name:Fields.stripeUserId, type:FieldTypes.dropdown, label:"Stripe Account Name", validators:[]},
    { name:Fields.nameOnCard, type:FieldTypes.text, label:"Name on Card", validators:[]},
    { name:Fields.saveCard, type:FieldTypes.singlecheckbox, label:"Save Card For Future Payments?", validators:[]},
    { name:Fields.prorationAllowed, type:FieldTypes.singlecheckbox, label:"Price Proration Allowed",
      tooltip:"Used with monthly plans, indicates if partial month usage is supported", validators:[]},
    { name:Fields.acceptedStudios, type:FieldTypes.multiselect, label:"Accepted in Studios", validators:[]},
    { name:Fields.activeDuration, type:FieldTypes.number, label:"Active Duration",
      tooltip:"Number of active days, used for expiring class packs and time based unlimited plans", validators:[], flex:"0 1 180px"},
    {
      name: Fields.baseRate, type: FieldTypes.amount, label: "Base Rate",
       validators: []
    },
    {
      name: Fields.classDuration, type: FieldTypes.number, label: "Class Duration",
      validators: []
    },

    {
      name: Fields.classParticipants, type: FieldTypes.number, label: "Class Participants", prefix:">=",
       validators: []
    },
    {
      name: Fields.rate, type: FieldTypes.amount, label: "Rate",
      tooltip: "Instructors rate", validators: []
    },
    {
      name: Fields.rateType, type: FieldTypes.dropdown, label: "Rate Type",
      choices:Enums.instructorRateTypes, validators: []
    },
    {
      name: Fields.startDate, type: FieldTypes.date, label: "Effective Date",
       validators: []
    },
    {
      name: Fields.endDate, type: FieldTypes.date, label: "End Date",
      validators: []
    },
    {
      name: Fields.amount, type: FieldTypes.amount, label: "Amount",
      validators: []
    },
    {
      name: Fields.instructorPaymentMethod, type: FieldTypes.dropdown, label: "Payment Method",
      choices:Enums.instructorPaymentMethod,
      validators: []
    },
    {
      name: Fields.checkNumber, type: FieldTypes.text, label: "Check Number",
      validators: []
    },

    {
      name: Fields.paymentDate, type: FieldTypes.date, label: "Payment Date",
      validators: []
    },
    {
      name: Fields.periodEndDate, type: FieldTypes.date, label: "Period End Date",
      validators: []
    },
    {
      name: Fields.periodStartDate, type: FieldTypes.date, label: "Period Start Date",
      validators: []
    },
    {
      name: Fields.totalClasses, type: FieldTypes.number, label: "Total Classes",
      validators: []
    },
    {
      name: Fields.totalHours, type: FieldTypes.number, label: "Total Hours",
      validators: []
    },

    {
      name: Fields.attendHistorySearchBy, type: FieldTypes.radio, label: "Query By", choices:Enums.attendHistorySearchBy,
      validators: []
    },

    {
      name: Fields.searchStartDate, type: FieldTypes.date, label: "Start Date",
      validators: []
    },
    {
      name: Fields.searchEndDate, type: FieldTypes.date, label: "End Date",
      validators: []
    },
    {
      name: Fields.instructorName, type: FieldTypes.text, label: "Instructor",
      validators: []
    },
    {
      name: Fields.traineeName, type: FieldTypes.text, label: "Member",
      validators: []
    },
    {
      name: Fields.planPurchaseDate, type: FieldTypes.date, label: "Purchase Date",
      validators: []
    },
    {
      name: Fields.lastCheckinTime, type: FieldTypes.date, label: "Last Attendance",
      validators: []
    },
  ];




  constructor() {
    for(let i = 0; i < this.fieldsConfig.length; i++) {
        this.fieldsCollection.set(this.fieldsConfig[i].name, this.fieldsConfig[i]);
    }
  }
  displayUserFn(user?: User): string | undefined {
    return user ? user.lastname + ", " + user.firstname : undefined;
  }
  userSearchString(user?: User):string  {
    return user ? user.lastname + ", " + user.firstname : "";
  }

  public getField(name:string):Field {
    if(this.fieldsCollection.has(name)) {
      return new Field(this.fieldsCollection.get(name));
    }
    console.error("Field with name " + name + " not found");
    return null;
  }

  public getFieldWithOverridableConfig(name:string, config:OverridableFieldConfigI):Field {
    const field = this.getField(name);
    if(field) {
      if(config.required) {
        field.setRequired(true);
      }
      if(config.filter) {
        field.filter = config.filter;
      }
      if(config.sort) {
        field.sort = config.sort;
      }
      if(config.dynchoices) {
        field.dynchoices = config.dynchoices;
      }
      if(config.choices) {
        field.choices = config.choices;
      }
      if(config.notifyonchange) {
        field.notifyonchange = config.notifyonchange;
      }
      if(config.lookupValue) {
        field.lookupValue = config.lookupValue;
      }
      if(config.type) {
        field.type = config.type;
      }
      if(config.validators) {
        for(let i = 0; i < config.validators.length; i++) {
          field.validators.push(config.validators[i]);
        }
      }
      if(config.searchStr) {
        field.searchStr = config.searchStr;
      }
      if(config.displayFn) {
        field.displayFn = config.displayFn;
      }
      if(config.focusListener) {
        field.focusListener = config.focusListener;
      }
      if(config.clearButton) {
        field.clearButton = config.clearButton;
      }
    }
    return field;
  }

  public getFieldsWithRequiredOverride(fieldsInfo:any[]):Field[] {
    const fields = [];
    for(let i = 0; i < fieldsInfo.length; i++) {
      if(this.fieldsCollection.has(fieldsInfo[i].name)) {
        const field = this.getField(fieldsInfo[i].name);
        if(fieldsInfo[i].required) {
          field.validators.push('required');
          field.required = true;
        }
        fields.push(field);
      } else {
        console.log("Field with name " + fieldsInfo[i].name + " not found");
      }
    }
    return fields;
  }

  public getFieldsCopy(names:string[]):Map<string, Field> {
    const fields = new Map<string, Field>();
    for(let i = 0; i < names.length; i++) {
      if(this.fieldsCollection.has(names[i])) {
        fields.set(names[i], new Field(this.fieldsCollection.get(names[i])));
      } else {
        console.log("Field with name " + names[i] + " not found");
      }
    }
    return fields;
  }

  public getFields(names:string[]):Field[] {
    const fields = [];
    for(let i = 0; i < names.length; i++) {
      if(this.fieldsCollection.has(names[i])) {
        fields.push(this.fieldsCollection.get(names[i]));
      } else {
        console.log("Field with name " + names[i] + " not found");
      }
    }
    return fields;
  }

}
