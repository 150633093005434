import { Directive, Inject, ElementRef,EventEmitter, Renderer, OnInit , Input} from '@angular/core';

@Directive({
  selector: '[fmFocus]'
})
export class FocusDirective implements OnInit {

  // tslint:disable-next-line:no-input-rename
  @Input('fmFocus') focusEvent: EventEmitter<boolean>;

  constructor(@Inject(ElementRef) private element: ElementRef, private renderer: Renderer) {
  }

  ngOnInit() {

    if(this.focusEvent) {
      this.focusEvent.subscribe(event => {
        this.renderer.invokeElementMethod(this.element.nativeElement, 'focus', []);
      });
    }

  }
}
