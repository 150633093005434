import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FmBaseService } from '../core/services/fm-base.service';
import { User } from '../core/models/user';
import { SearchUserFilter } from '../models/search-user-filter';


@Injectable({
  providedIn: 'root'
})
export class UserService extends FmBaseService<User> {

  constructor(http: HttpClient) {
    super(http, "UserService");
  }

  public getUserByEmail(email:any) {
    console.log("Checking if user with emal " + email + " exists ");
    return this.postCall("/get/user/email",{ email: email });
  }

  public registerNewUser(user: any) {

    console.log("Registering user ", JSON.stringify(user));

    return this.postCall("/register/user", user);

  }
  // will add login i.e. password
  public createLogin(user: any) {

    console.log("Creating login for user ", JSON.stringify(user));

    return this.postCall("/create/user/account", user);

  }

  public searchUser(filter:SearchUserFilter) {
    console.log("Searching users with filter ", JSON.stringify(filter));

    return this.postCall("/search/user", {filter:filter} );
  }

}
