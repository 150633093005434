export class Address {
  constructor(data:any) {
    this.id=data.id;
    this.firstname=data.firstname;
    this.lastname=data.lastname;
    this.address1=data.address1;
    this.address2=data.address2;
    this.city=data.city;
    this.zipcode=data.zipcode;
    this.state=data.state;
    this.latitude=data.latitude;
    this.longitude=data.longitude;

  }
  id?: string;
  firstname: string;
  lastname: string;
  address1: string;
  address2?: string;
  city: string;
  zipcode: string;
  state: string;
  latitude?: number;
  longitude?: number;

  static edit(address:Address, data: any)  {

    // TODO check if anything changed and call out to the map service to get the coordinates
    if(address) {
      address.firstname=data.firstname;
      address.lastname=data.lastname;
      address.address1=data.address1;
      address.address2=data.address2;
      address.city=data.city;
      address.zipcode=data.zipcode;
      address.state=data.state;
    }

  }
}
