import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { UpdatePasswordComponent } from './update-password/update-password.component';
import { AppMaterialModule } from '../app-material/app-material.module';
import { FormModule } from '../form/form.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AuthRoutes as routes } from './auth.routes';
import { RouterModule } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { RoleGuard } from './role.guard';
import { UserActivationComponent } from './user-activation/user-activation.component';

@NgModule({
  imports: [
    CommonModule,
    AppMaterialModule,
    FormModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule.forChild(routes),

  ],
  declarations: [LoginComponent, SignUpComponent, ForgetPasswordComponent, UpdatePasswordComponent, UserActivationComponent],
  exports:[LoginComponent],
  providers:[AuthGuard, RoleGuard]
})
export class AuthModule { }
