import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse} from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { throwError } from 'rxjs';

export interface ServiceRequest {
  url: string;
  request: any;
}


@Injectable()
export abstract class FmBaseService<T>  {

  private apiLink: string = environment.API_ENDPOINT;

  constructor(private http: HttpClient, private serviceName: string) {
    console.log("Created service " + this.serviceName);
  }

  protected handleError(error: HttpErrorResponse) {
     if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error},  ` +
        `url: ${error.url}`);

    }
    // return an ErrorObservable with a user-facing error message
    return throwError(
      'Server error; please try again later.');
  }


  protected postCall(url:string, request:any) {
    return this.http.post(`${this.apiLink}` + url, request)
     .pipe(
        // retry(3),
        catchError(this.handleError)
      );
  }

  protected getCall(url:string) {
    return this.http.get<T>(`${this.apiLink}` + url)
      .pipe(
        retry(3),
        catchError(this.handleError)
      );
  }

}
