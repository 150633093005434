import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';


@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(public snackBar: MatSnackBar) {

   }

  public displaySuccessMessage(message:string) {
    this.snackBar.open(message, '', {duration:5000});
  }

  public displayErrorMessage(message:string) {
    this.snackBar.open(message, 'ERROR', {duration:5000});
  }

}
