import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldComponent } from './field/field.component';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { EqualValidator } from './validators/equal-validator.directive';
import { FormComponent } from './form/form.component';
import { AppMaterialModule } from '../app-material/app-material.module';
import { UserListComponent } from './user-list/user-list.component';
import { QuillModule } from 'ngx-quill';
import { ReactiveFormsModule } from '@angular/forms';
import { FocusDirective } from './directives/focus.directive';
import { FlexLayoutModule } from "@angular/flex-layout";
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { NgxStripeModule } from 'ngx-stripe';

@NgModule({
  imports: [
    CommonModule,
    AppMaterialModule,
    QuillModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    NgxStripeModule
  ],
  declarations: [FieldComponent, DynamicFormComponent,
    EqualValidator, FormComponent, UserListComponent, FocusDirective, ConfirmDialogComponent],
  exports: [
      DynamicFormComponent,
      FormComponent,
      ConfirmDialogComponent
    ]
})
export class FormModule { }
