import { format, formatNumber } from 'libphonenumber-js';

export class PhoneFormatter  {

  public static convertToServerFormat(phone:string):string {
    if(phone) {
      return phone.replace(/[()\\s-]+/g, '');
    }
    return phone;
  }

  public static convertToDisplayFormat(phone:string):string {
    if (phone) {
      return formatNumber({ country: 'US', phone: phone }, 'National');
    }
    return phone;
  }

}
