import { Component } from '@angular/core';
import { MenuService } from './menu.service';
import { AuthService } from '../../auth/auth.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'fm-menu',
  templateUrl: './menu.component.html',
  providers: [MenuService]
})
export class MenuComponent {
  currentLang = 'en';
  isLoggedIn$: Observable<boolean>;

  constructor(
    public menuService: MenuService,
    private authService:AuthService) {
      this.isLoggedIn$ = this.authService.isLoggedIn;
  }

  getUrl(args:string[]):string {
    let retString = "";
    for(let i = 0; i < args.length; i++) {
      retString = retString + "/" + args[i];
    }
    return retString;
  }

  /*addMenuItem(): void {
    this.menuService.add({
      state: 'menu',
      name: 'MENU',
      type: 'sub',
      icon: 'trending_flat',
      children: [
        {state: 'menu', name: 'MENU'},
        {state: 'timeline', name: 'MENU'}
      ]
    });
  }*/
}
