import { Component, OnInit,
  EventEmitter, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Fields } from '../../models/fields';
import { User } from '../../core/models/user';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';
import { FieldConfigService } from '../../services/field-config.service';
import { FieldChangeHandler } from '../../form/action/field-change-handler';

@Component({
  selector: 'fm-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit, AfterViewInit, FieldChangeHandler {
  form: FormGroup;

  fieldConfigPanel = [];
  fieldChangeHander:FieldChangeHandler;
  error = false;
  errorMessage = "";
  info = false;
  infoMessage = "";
  user:User;
  passwordFocusEmitter;
  userPending = false;
  email = null;

  constructor(private fb: FormBuilder, private us: UserService,
    private router: Router, private fieldConfig:FieldConfigService) {
     }

  ngOnInit() {
    this.passwordFocusEmitter = new EventEmitter<boolean>();
    this.fieldChangeHander = this;
    const equalValConfigOverride = {required:true, validators:["validateEqual"]};
    const passwordConfigOverride = {focusListener:this.passwordFocusEmitter, required:true, validators:["validateEqual"]};
    this.fieldConfigPanel.push(
      this.fieldConfig.getFieldWithOverridableConfig(Fields.email, equalValConfigOverride));
    this.fieldConfigPanel.push(
      this.fieldConfig.getFieldWithOverridableConfig(Fields.confirmemail, equalValConfigOverride));
    this.fieldConfigPanel.push(this.fieldConfig.getFieldWithOverridableConfig(Fields.firstname, {required:true}));
    this.fieldConfigPanel.push(this.fieldConfig.getFieldWithOverridableConfig(Fields.lastname, {required:true}));
    this.fieldConfigPanel.push(
      this.fieldConfig.getFieldWithOverridableConfig(Fields.password, passwordConfigOverride));
    this.fieldConfigPanel.push(
      this.fieldConfig.getFieldWithOverridableConfig(Fields.confirmpassword, equalValConfigOverride));
    this.fieldConfigPanel.push(this.fieldConfig.getFieldWithOverridableConfig(Fields.usertype, {required:true}));

    this.createForm();
  }

  ngAfterViewInit() {

  }

  private createForm() {
    this.form = this.fb.group({sections:this.fb.array([])});
  }

  submit() {
    console.log('clicked submit ');
    if(this.user) {
      this.user.password = this.form.value.sections[0].password;
      this.us.createLogin(this.user).subscribe(result => {
        console.log("successfully subscribed " + JSON.stringify(result));
        this.router.navigate(["/login", {successRegistering:result[0].firstname}]);
      },
      err => {
        console.log("Error " + err);
        this.error = true;
        this.errorMessage = "Error registering user." + err;
      });
    } else {
      this.email = this.form.value.sections[0].email;
      this.us.registerNewUser(this.form.value.sections[0]).subscribe(result => {
        console.log("successfully subscribed ");
        if(result[0].status === User.PENDING) {
          this.userPending = true;
        } else {
          this.router.navigate(["/login", {successRegistering:result[0].firstname}]);
        }
      },
      err => {
        console.log("Error " + err);
        this.error = true;
        this.errorMessage = "Error registering user." + err;
      });

    }


  }

  cancel() {
    console.log('clicked cancel ');
    this.router.navigate(["/login"]);
  }


  fieldUpdated(value:any, field:string) {
    if(field === "email") {
      console.log("Retrieving user by email");
      this.info = false;
      this.error = false;
      this.infoMessage = "";
      this.errorMessage = "";
      this.us.getUserByEmail(value).subscribe(
        retValue => {
          console.log("User exists " + JSON.stringify(retValue));
          const result = retValue as any;
          if(result && result.length > 0) {
            this.user = result[0];
            if(this.user.password) {
              console.log("This user is already registered.");
              this.error = true;
              this.errorMessage = "User with email " + value + " already exists.";

            } else {
              this.info = true;
              this.infoMessage = "User with this email exists. Please set your password.";
              const patchValue = {sections:[this.user]};
              patchValue.sections[0]['confirmemail'] = this.user.email;
              this.form.patchValue(patchValue);
              console.log("Form Value " + JSON.stringify(this.form.value));
              this.passwordFocusEmitter.emit(true);
            }
          }
        }
      );
    }

  }

  isValidForm():boolean {
    if(!this.form.valid) {
      return false;
    }
    if(this.error) {
      return false;
    }
    return true;
  }
  valueChange(value:any, field:string) {

  }
}
