import { Address } from "./address";
import { PhoneFormatter } from "../utils/phone-formatter";
import { PaymentMethod } from "./payment-method";

export class User {

  static readonly ACTIVE = "A";
  static readonly PENDING = "P";
  static readonly DELETED = "D";
  static readonly LOCKED = "L";

  constructor(data:any) {
    this.id=data.id;
    this.email=data.email;
    this.firstname=data.firstname;
    this.lastname=data.lastname;
    this.password=data.password;
    this.usertype=data.usertype;
    this.homephone= PhoneFormatter.convertToServerFormat( data.homephone);
    this.mobilephone=PhoneFormatter.convertToServerFormat(data.mobilephone);
    this.foundFitMaze=data.foundFitMaze;
    this.preferredContactMethod=data.preferredContactMethod;
    this.termsAcceptance=data.termsAcceptance;
    this.homeaddress=data.homeaddress;
    this.profilePicture = data.profilePicture;
    this.paymentMethod = data.paymentMethod;
    this.status = data.status;
  }

  id?: string;
  email: string;
  firstname: string;
  lastname: string;
  password?: string;
  usertype: string;
  homephone?: string;
  mobilephone?: string;
  foundFitMaze?: string;
  preferredContactMethod?: string;
  termsAcceptance?: string;
  homeaddress?: Address;
  profilePicture?:string;
  profilePictureId?:string;
  studios?:string[];
  paymentMethod?:PaymentMethod;
  status?:string;

  public static edit(user:User, data:any) {
    Address.edit(user.homeaddress, user);
    user.firstname=data.firstname;
    user.lastname=data.lastname;
    user.homephone=data.homephone;
    user.mobilephone=data.mobilephone;
    user.foundFitMaze=data.foundFitMaze;
    user.preferredContactMethod=data.preferredContactMethod;
    user.homeaddress=data.homeaddress;
    user.homephone= PhoneFormatter.convertToServerFormat(user.homephone);
    user.mobilephone= PhoneFormatter.convertToServerFormat(user.mobilephone);
    user.status = data.status;
  }

}
