import { Injectable } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { Enums } from '../../models/enums';
import { environment } from '../../../environments/environment';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

const MENUITEMS_OWNER = [
  {
    state: 'fitmaze',
    name: 'HOME',
    type: 'link',
    icon: 'explore'
  },
  {
    state: 'studioapp/settings',
    name: 'SETTINGS',
    type: 'sub',
    icon: 'settings_applications',
    /*badge: [
      {type: 'red', value: '5'}
    ],*/
    children: [
      {state: 'studios', name: 'STUDIOS', path: '/studioapp/settings/studios'},
      {state: 'classes', name: 'CLASSES', path: '/studioapp/settings/classes'},
      {state: 'classrates', name: 'PAYMENT PLANS', path: '/studioapp/settings/classrates'},
      {state: 'instructors', name: 'INSTRUCTORS', path: '/studioapp/settings/instructors'},
      {state: 'trainees', name: 'TRAINEES', path: '/studioapp/settings/trainees'},
    ]
  },
  {
    name: 'PAYMENTS',
    type: 'sub',
    icon: 'payment',
    state: 'studioapp/payment-processing',
    children: [
      {state: 'stripe-register', name: 'STRIPE', path: 'studioapp/payment-processing/stripe-register'}
    ]
  },
  {
    state: '/studioapp/schedule',
    name: 'CALENDAR',
    type: 'link',
    icon: 'schedule'
  },
  {
    state: '/studioapp/traineeEnrollment',
    name: 'ENROLLMENT',
    type: 'link',
    icon: 'note_add',
  },
  {
    state: '/studioapp/attendance',
    name: 'ATTENDANCE',
    type: 'link',
    icon: 'done',
  },
  {
    state: '/studioapp/pay-instructor',
    name: 'PAY INSTRUCTOR',
    type: 'link',
    icon: 'swap_horiz',
  },
  {
    state: '/studioapp/dashboard',
    name: 'DASHBOARD',
    type: 'link',
    icon: 'dashboard',
  }



];

const MENUITEMS_TRAINEE = [
  {
    state: '/',
    name: 'HOME',
    type: 'link',
    icon: 'explore'
  },
  /*{
    state: 'favorites',
    name: 'FAVORITES',
    type: 'sub',
    icon: 'apps',
    children: [
      {state: 'studiosTrainee', name: 'STUDIOS', path:'/traineeapp/favorites/studiosTrainee'},
      {state: 'classes', name: 'CLASSES', path:'/traineeapp/favorites/classes'},
      {state: 'trainers', name: 'TRAINERS', path:'/traineeapp/favorites/trainers'}
    ]
  },*/
  {
    state: '/traineeapp/classes',
    name: 'CLASSES',
    type: 'link',
    icon: 'photo'
  },
  {
    state: '/traineeapp/enrollment',
    name: 'ENROLLMENT',
    type: 'link',
    icon: 'note_add'
  },
  /*{
    state: '/traineeapp/traineeSchedule',
    name: 'SCHEDULE',
    type: 'link',
    icon: 'schedule'
  },*/
  {
    state: '/traineeapp/studiosTrainee',
    name: 'STUDIOS',
    type: 'link',
    icon: 'view_column',
  },
  {
    state: '/traineeapp/reports',
    name: 'REPORTS',
    type: 'link',
    icon: 'show_chart',
  }
];
const MENUITEMS_DEFAULT = [
  {
    state: 'fitmaze',
    name: 'HOME',
    type: 'link',
    icon: 'explore'
  },
  {
    state: 'login',
    name: 'LOGIN',
    type: 'link',
    icon: 'account_circle',
  },
  {
    state: 'register',
    name: 'REGISTER',
    type: 'link',
    icon: 'description',
  }
];

@Injectable()
export class MenuService {
  constructor(private authService: AuthService) {

  }
  getAll(): Menu[] {
    if(this.authService.isUserLoggedIn()) {
      if(this.authService.userProfile.usertype === Enums.USER_TYPE_OWNER) {
        return MENUITEMS_OWNER;
      } else if(this.authService.userProfile.usertype === Enums.USER_TYPE_TRAINEE) {
        return MENUITEMS_TRAINEE;
      }
    } else {
      return MENUITEMS_DEFAULT;
    }

  }



}
