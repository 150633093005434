import { Injectable, OnDestroy } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { AuthService } from './auth.service';
import { Subscription, Observable } from 'rxjs';
import { Enums } from '../models/enums';


@Injectable()
export class AuthGuard implements CanActivate, OnDestroy {

  isAuthenticated: boolean;
  subscription: Subscription;

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    console.log("Calling can activate " + state.url);
    this.subscription = this.authService.isLoggedIn.subscribe(
      isAuthenticated => {
        this.isAuthenticated = isAuthenticated;
        if (!isAuthenticated) {
          this.router.navigate(
            ['/login'],
            { queryParams: { returnUrl: state.url } }
          );
        }
        /*else {
          console.log("Authenticated! " + state.url);
          if (state.url) {
            // this.router.navigate([state.url]);
          } else
          if (this.authService.userProfile.usertype === Enums.USER_TYPE_OWNER) {
            console.log("Navigating to studios");
            this.router.navigate(['settings/studios']);
          } else if (this.authService.userProfile.usertype === Enums.USER_TYPE_TRAINEE) {
            console.log("Navigating to membership");
            this.router.navigate(['membership']);
          }
        }*/
      }
    );

    return this.isAuthenticated;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
