import { Injectable, OnDestroy } from '@angular/core';
import {
  CanActivate,
  CanLoad,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Route,
  Router
} from '@angular/router';
import { AuthService } from './auth.service';
import { Subscription, Observable } from 'rxjs';
import { Enums } from '../models/enums';


@Injectable()
export class RoleGuard implements CanLoad, CanActivate {

  subscription: Subscription;

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  canLoad(route: Route): boolean {

    console.log("Calling can load in RoleGuard");
    const expectedRole = route.data.expectedRole;
    if ( !this.authService.isUserLoggedIn() || this.authService.userProfile.usertype !== expectedRole) {
      return false;
    }
    return true;
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    console.log("Calling can load in RoleGuard");
    const expectedRole = next.data.expectedRole;
    if ( !this.authService.isUserLoggedIn() || this.authService.userProfile.usertype !== expectedRole) {
      return false;
    }
    return true;
  }
}

