import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder} from '@angular/forms';
import { AuthService} from '../../auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FieldConfigService } from '../../services/field-config.service';
import { Fields } from '../../models/fields';
import { Subscription } from 'rxjs';

@Component({
  selector: 'fm-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
  loginform: FormGroup;
  successRegistering:string;
  returnUrl:string;
  loginSubs: Subscription;

  fieldConfigPanel = [];

  constructor(private fb: FormBuilder,
    private authService: AuthService,
    private router:Router,
    private fieldConfig: FieldConfigService,
    private route: ActivatedRoute) {

      this.fieldConfigPanel.push(
        this.fieldConfig.getFieldWithOverridableConfig(Fields.email, {required:true}));
        this.fieldConfigPanel.push(
          this.fieldConfig.getFieldWithOverridableConfig(Fields.password, {required:true}));

       this.redirectIfUserLoggedIn();

  }

  ngOnInit() {
    this.createForm();
    this.successRegistering = this.route.snapshot.paramMap.get('successRegistering');
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  ngOnDestroy() {
    if (this.loginSubs) { this.loginSubs.unsubscribe(); }
  }

  private createForm() {
    this.loginform = this.fb.group({sections:this.fb.array([])});
  }

  login() {
    console.log('clicked login ' + JSON.stringify(this.loginform.value));
    if (this.loginform.valid) {
      this.authService.login(this.loginform.value.sections[0].email, this.loginform.value.sections[0].password);
    }

  }
  redirectIfUserLoggedIn() {
    this.authService.isLoggedIn.subscribe(
      data => {
        if (data === true) { this.router.navigate([this.returnUrl]); }
      }
    );
  }

  cancel() {
    this.authService.cancel();
  }
}
