import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable } from "rxjs";
import { User } from '../../core/models/user';
import { FormControl } from '@angular/forms';
import { Field } from '../../core/models/field';

@Component({
  selector: 'fm-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {

  @Input() userList:Observable<User>;
  @Input() form:FormControl;
  @Input() initList:User[];
  @Input() field:Field;

  @Output()
  private removeUser = new EventEmitter();
  constructor() { }

  ngOnInit() {
    this.initList = this.initList?this.initList:[];
    // this.form.get(FmFields.traineeList).setValue(this.initList);
    this.userList.subscribe(users => {
      this.form.get(this.field.name).setValue(users);
    });
  }

  remove(value:any) {
    console.log("remove " + value);
    this.removeUser.emit(value);
  }

}
