import { Component, Input, Output, OnInit, AfterContentInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder} from '@angular/forms';
import { EqualValidator} from '../validators/equal-validator.directive';
import { FieldChangeHandler } from '../action/field-change-handler';
import { startWith } from 'rxjs/operators';

@Component({
  selector: 'fm-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.css']
})
export class DynamicFormComponent implements OnInit, AfterContentInit {

  public static phoneregex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
  public static pwdregex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
  public static cityregex = /^[a-zA-z] ?([a-zA-z]|[a-zA-z] )*[a-zA-z]$/;
  public static addressregex = /^[a-zA-Z0-9-\/] ?([a-zA-Z0-9-\/]|[a-zA-Z0-9-\/] )*[a-zA-Z0-9-\/]$/;
  public static zipcoderegex = /^[0-9]{5}(?:-[0-9]{4})?$/;

  @Input() fieldConfig;
  @Input() parentForm: FormArray;
  @Input() fieldChangeHander: FieldChangeHandler;
  @Input() defaultValues:any;
  @Input() disabled = false;
  @Output() exptype: string;

  form: FormGroup;

  constructor(private fb: FormBuilder) {
  }

  ngOnInit() {
    this.createForm();
  }

  ngAfterContentInit () {
    if(this.defaultValues) {
      this.form.patchValue(this.defaultValues);
    }
    if(this.disabled) {
      this.form.disable();
    }
  }

  private mapValidators(inputfield) {
    const formValidators = [];
    const validators = inputfield.validators;
    const equalname = inputfield.equalname;
    const reverse = inputfield.reverse;
    const validationpattern = inputfield.validationpattern;
    if(validators) {
      validators.forEach(element => {
        if(element === 'required') {
          formValidators.push(Validators.required);
        } else if (element === 'phone') {
          formValidators.push(Validators.pattern(DynamicFormComponent.phoneregex));
        } else if (element === 'email') {
          formValidators.push(Validators.email);
        } else if (element === 'password') {
          // formValidators.push(Validators.pattern(DynamicFormComponent.pwdregex));
        } else if (element === 'validateEqual') {
          formValidators.push(new EqualValidator(equalname, reverse));
        } else if (element === 'pattern' && validationpattern) {
          formValidators.push(Validators.pattern(validationpattern));
        }
      });
    }
    return formValidators;
  }

  valueChange(value:any, field:string) {
    if(this.fieldChangeHander) {
      this.fieldChangeHander.valueChange(value, field);
    }
  }

  private createForm() {
    this.form = this.fb.group({});
    this.parentForm.push(this.form);

    this.fieldConfig.forEach(element => {
      if(element.type === "checkbox") {
        this.buildCheckboxList(element);
      } else if(element.type === "checkbox_subcat") {
        this.buildCheckboxWithSubCatList(element);
      } else {
        this.form.addControl(element.name, new FormControl(element.defaultValue, this.mapValidators(element)));

       if(element.type === "autocomplete") {

          this.form.get(element.name).valueChanges.pipe(startWith<any>('')).subscribe(data => this.valueChange(data, element.name));
        }
      }
    });

  }

  private buildCheckboxList(choices:any) {
    choices.choices.forEach(element => {
      this.form.addControl(element.name, new FormControl());
    });
  }

  private buildCheckboxWithSubCatList(choices:any) {
    choices.choices.forEach(element => {
      this.form.addControl(element.name, new FormControl());
      element.subcategories.forEach(el2 => {
        this.form.addControl(el2.name, new FormControl());
      });
    });
  }
}
